/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js'
import router from '@/router'
import axios from '../../../src/axios.js'

export default {

  refreshUserObject ({commit}) {
    return new Promise((resolve, reject) => {

      jwt.refreshUserObject()
        .then(response => {
          if (response.data.data) {
            vm.$vs.loading.close()

            commit('UPDATE_USER_INFO', response.data.data, {root: true})

            return resolve(response.data.data)
          }

          reject(response.data)
        })
        .catch(exception => reject(exception))
    })
  },
}
