// axios
import axios from 'axios';

let baseUrl = 'https://app-api.rapidseminars.com/v1/';

if (location.href.includes('-staging')) {
  baseUrl = 'https://app-api-staging.rapidseminars.com/v1/';
} else if (location.href.includes('-dev')) {
  baseUrl = 'https://app-api-dev.rapidseminars.com/v1/';
}

// baseUrl = 'http://localhost:9190/v1/'

export default axios.create({baseURL: baseUrl});
