import Vue from 'vue';
import axios from '../../../axios/index.js';
import store from '../../../../store/store.js';

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default {
  async init() {

    if (localStorage['sessionToken'] !== undefined) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage['sessionToken']}`;

      // TODO: Find a way to do this efficiently
      if (Vue.prototype.$gateway === undefined && false) {


        try {
          const response = await axios.get('users/@me/tokens');

          let data = response.data;

          if (response.status === 200 && data.data.user_id) {
            data = data.data;
            localStorage.setItem('accessToken', data.access_token)
            localStorage.setItem('refreshToken', data.refresh_token)

            Vue.prototype.$gateway = new Gateway({
              service: 'rapidseminars',
              accessToken: localStorage['accessToken'],
              autoConnect: true,
              autoReconnect: true,
              autoSendBrowsing: true,
            });
          }

        } catch (e) {

        }

      }
    }

    axios.interceptors.response.use((response) => {
      return response;
    }, (error) => {

      // const { config, response: { status } } = error
      const {config, response} = error;
      const originalRequest = config;

      if (response && response.status === 401) {

        if (!location.href.includes('/authorize')) {
          localStorage.removeItem('sessionToken');
          vm.$router.push('/authorize')
        }

        return Promise.resolve();
      } else if (response && response.status === 429) {
        let time = response.data.data.resets;

        vm.$vs.notify({
          color: 'danger',
          title: 'You are being ratelimited',
          text: `You must wait ${this.timeToGo(
            time * 1000)} to perform this action again.`,
        });
      }
      return Promise.reject(error);
    });

  },
  timeToGo(diff) {

    const hours = diff / 3.6e6 | 0;
    const mins = diff % 3.6e6 / 6e4 | 0;
    const secs = Math.round(diff % 6e4 / 1e3);

    let display = '';

    if (hours > 0) {
      display += `${hours} hour${hours === 1 ? '' : 's'}`;
    }
    if (mins > 0) {
      display += `${mins} minute${mins === 1 ? '' : 's'}`;
    }
    if (secs > 0) {
      display += `${mins > 0 || hours > 0
                    ? ' and'
                    : ''} ${secs} second${secs === 1 ? '' : 's'}`;
    }

    return display;
  },

  refreshUserObject() {
    return axios.get('users/@me');
  },
};
