/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import App from './App.vue';

import * as Sentry from '@sentry/browser';
import {Vue as VueIntegration} from '@sentry/integrations';

//Sentry.init({
//  dsn: 'https://4bd52f6d4e6e48aba08b08670fd2c7c9@o426118.ingest.sentry.io/5370057',
//  integrations: [new VueIntegration({Vue, attachProps: true})],
//  environment: localStorage.getItem('dev') ? 'development' : 'production',
//});

import {Integrations} from '@sentry/tracing';

//Sentry.init({
//  dsn: 'https://4bd52f6d4e6e48aba08b08670fd2c7c9@o426118.ingest.sentry.io/5370057',
//  release: 'rapid-seminars@' + process.env.npm_package_version,
//  integrations: [new Integrations.BrowserTracing()],
//  environment: localStorage.getItem('dev') ? 'development' : 'production',
//  tracesSampleRate: localStorage.getItem('dev') ? 0 : .1,
//});

// Vuesax Component Framework
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax

// axios
import axios from './axios.js';
// API Calls
import './http/requests';

// Theme Configurations
import '../themeConfig.js';

// ACL
import acl from './acl/acl';

// Globally Registered Components
import './globalComponents.js';

// Styles: SCSS
import './assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from './router';

// Vuex Store
import store from './store/store';

// i18n
import i18n from './i18n/i18n';

// Vuexy Admin Filters
import './filters/filters';

//Vue Transitions
import Transitions from 'vue2-transitions'
Vue.use(Transitions)

// Clipboard
import VueClipboard from 'vue-clipboard2';
// Tour
import VueTour from 'vue-tour';
// VeeValidate
import VeeValidate from 'vee-validate';
// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps';
// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer';
import {VueReCaptcha} from 'vue-recaptcha-v3';
// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

import VuePlyr from 'vue-plyr'
Vue.use(VuePlyr)

import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

Vue.use(Vuesax);

Vue.prototype.$http = axios;

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-NX1DNGDTC4" },
  appName: 'Web Portal',
  pageTrackerScreenviewEnabled: true
});

Vue.use(VueClipboard);

Vue.use(VueTour);
require('vue-tour/dist/vue-tour.css');

Vue.use(VeeValidate);

Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'YOUR_KEY',
    libraries: 'places', // This is required if you use the Auto complete plug-in
  },
});

Vue.use(VueHammer);

Vue.use(VueReCaptcha, {
  siteKey: '6LdCUrgZAAAAAJiebNDJGdAC6adirCc4fGWLSPXt',
  loaderOptions: {
    size: 'invisible',
    autoHideBadge: true,
  },
});

import Nl2br from 'vue-nl2br';

Vue.component('nl2br', Nl2br);

// Feather font icon
require('./assets/css/iconfont.css');

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

const vm = new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app');
window.vm = vm;
