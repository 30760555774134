import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0};
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/dashboard',
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('./views/DashboardUser.vue'),
          meta: {
            rule: 'user',
            // pageTitle: 'Dashboard',
          },
        },

        {
          path: '/courses',
          name: 'app-courses-selector',
          component: () => import('./views/pages/CourseSelector'),
          meta: {
            rule: 'user',
          },
        },
        {
          path: '/courses/:courseId',
          name: 'app-courses-view',
          component: () => import('./views/pages/CourseView'),
          meta: {
            rule: 'user',
            // pageTitle: 'Course View',
          },
        },
        {
          path: '/courses/:courseId/:moduleId/:lessonId',
          name: 'app-lessons-view',
          component: () => import('./views/pages/LessonView'),
          meta: {
            rule: 'user',
            pageTitle: 'Lesson View',
            titleless: true
          },
        },

        // =============================================================================
        // Application Routes
        // =============================================================================
        {
          path: '/a/subscriptions',
          name: 'app-subscription-list',
          component: () => import('@/views/apps/accounting/subscription-list/SubscriptionList'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Subscriptions', url: '/a/subscriptions'},
              {title: 'List', active: true},
            ],
            pageTitle: 'Subscription List',
            rule: 'accountant',
          },
        },
        {
          path: '/a/videos',
          name: 'app-video-list',
          component: () => import('@/views/apps/videos/video-list/VideoList'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Videos', url: '/a/videos'},
              {title: 'List', active: true},
            ],
            pageTitle: 'Video List',
            rule: 'proctor',
          },
        },
        {
          path: '/a/videos/:videoId',
          name: 'app-video-view',
          component: () => import('@/views/apps/videos/VideoView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Videos', url: '/a/videos'},
              {title: 'View', active: true},
            ],
            pageTitle: 'Video View',
            rule: 'proctor',
          },
        },
        {
          path: '/a/videos/:videoId/edit',
          name: 'app-video-edit',
          component: () => import('@/views/apps/videos/video-edit/VideoEdit'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Videos', url: '/a/videos'},
              {title: 'Edit', active: true},
            ],
            pageTitle: 'Video Editor',
            rule: 'proctor',
          },
        },
        {
          path: '/a/attachments',
          name: 'app-attachment-list',
          component: () => import('@/views/apps/attachments/attachment-list/AttachmentList'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Attachments', url: '/a/attachments'},
              {title: 'List', active: true},
            ],
            pageTitle: 'Attachment List',
            rule: 'proctor',
          },
        },
        {
          path: '/a/attachments/:attachmentId',
          name: 'app-attachment-view',
          component: () => import('@/views/apps/attachments/AttachmentView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Attachments', url: '/a/attachments'},
              {title: 'View', active: true},
            ],
            pageTitle: 'Attachment View',
            rule: 'proctor',
          },
        },
        {
          path: '/a/attachments/:attachmentId/edit',
          name: 'app-attachment-edit',
          component: () => import('@/views/apps/attachments/attachment-edit/AttachmentEdit'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Attachments', url: '/a/attachments'},
              {title: 'Edit', active: true},
            ],
            pageTitle: 'Attachment Editor',
            rule: 'proctor',
          },
        },
        {
          path: '/a/lessons',
          name: 'app-lesson-list',
          component: () => import('@/views/apps/lessons/lesson-list/LessonList'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Lessons', url: '/a/lessons'},
              {title: 'List', active: true},
            ],
            pageTitle: 'Lesson List',
            rule: 'proctor',
          },
        },
        {
          path: '/a/lessons/:lessonId',
          name: 'app-lesson-view',
          component: () => import('@/views/apps/lessons/LessonView'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Lessons', url: '/a/lessons'},
              {title: 'View', active: true},
            ],
            pageTitle: 'Lesson View',
            rule: 'proctor',
          },
        },
        {
          path: '/a/lessons/:lessonId/edit',
          name: 'app-lesson-edit',
          component: () => import('@/views/apps/lessons/lesson-edit/LessonEdit'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Lessons', url: '/a/lessons'},
              {title: 'Edit', active: true},
            ],
            pageTitle: 'Lesson Edit',
            rule: 'proctor',
          },
        },
        {
          path: '/a/modules',
          name: 'app-module-list',
          component: () => import('@/views/apps/modules/module-list/ModuleList'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Modules', url: '/a/modules'},
              {title: 'List', active: true},
            ],
            pageTitle: 'Module List',
            rule: 'proctor',
          },
        },
        {
          path: '/a/modules/:moduleId',
          name: 'app-module-view',
          component: () => import('@/views/apps/modules/ModuleView'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Modules', url: '/a/modules'},
              {title: 'View', active: true},
            ],
            pageTitle: 'Module View',
            rule: 'proctor',
          },
        },
        {
          path: '/a/modules/:moduleId/edit',
          name: 'app-module-edit',
          component: () => import('@/views/apps/modules/module-edit/ModuleEdit'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Modules', url: '/a/modules'},
              {title: 'Edit', active: true},
            ],
            pageTitle: 'Module Edit',
            rule: 'proctor',
          },
        },
        {
          path: '/a/courses',
          name: 'app-course-list',
          component: () => import('@/views/apps/course/course-list/CourseList'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Courses', url: '/a/courses'},
              {title: 'List', active: true},
            ],
            pageTitle: 'Course List',
            rule: 'proctor',
          },
        },
        {
          path: '/a/courses/:courseId',
          name: 'app-course-view',
          component: () => import('@/views/apps/course/CourseView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Courses', url: '/a/courses'},
              {title: 'View', active: true},
            ],
            pageTitle: 'Course View',
            rule: 'proctor',
          },
        },
        {
          path: '/a/courses/:courseId/edit',
          name: 'app-course-edit',
          component: () => import('@/views/apps/course/course-edit/CourseEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Courses', url: '/a/courses'},
              {title: 'Edit', active: true},
            ],
            pageTitle: 'Course Edit',
            rule: 'proctor',
          },
        },

        {
          path: '/a/users',
          name: 'app-user-list',
          component: () => import('@/views/apps/user/user-list/UserList.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', url: '/a/users'},
              {title: 'List', active: true},
            ],
            pageTitle: 'User List',
            rule: 'support_t3',
          },
        },
        {
          path: '/a/users/:userId',
          name: 'app-user-view',
          component: () => import('@/views/apps/user/UserView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', url: '/a/users'},
              {title: 'View', active: true},
            ],
            pageTitle: 'User View',
            rule: 'employee',
          },
        },
        {
          path: '/a/users/:userId/edit',
          name: 'app-user-edit',
          component: () => import('@/views/apps/user/user-edit/UserEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', url: '/a/users'},
              {title: 'Edit', active: true},
            ],
            pageTitle: 'User Edit',
            rule: 'support_t2',
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/authorize',
          name: 'authorize-panel',
          component: () => import('./views/pages/PanelAuthorize'),
          meta: {
            rule: 'visitor',
            pageTitle: 'Authorize Panel',
          },
        },
        {
          path: '/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'visitor',
            pageTitle: 'Error 500',
          },
        },
        {
          path: '/not-authorized',
          redirect: '/authorize',
        },
        {
          path: '/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'visitor',
            pageTitle: 'Maintenance',
          },
        },
      ],
    },

    {
      name: 'app-user-settings',
      path: '/settings',
      beforeEnter(to, from, next) {
        window.location = 'https://portal.rapid.education/settings';
      },
      meta: {
        rule: 'user',
        pageTitle: 'User Settings',
      },
    },
    {
      name: 'app-portal-redirect',
      path: '/portal',
      beforeEnter(to, from, next) {
        window.location = 'https://portal.rapid.education';
      },
      meta: {
        rule: 'visitor',
        pageTitle: 'Rapid Portal',
      },
    },
    {
      name: 'app-portal-shop-redirect',
      path: '/portal/shop',
      beforeEnter(to, from, next) {
        window.location = 'https://portal.rapid.education/shop?service=rapidseminars';
      },
      meta: {
        rule: 'visitor',
        pageTitle: 'Rapid Portal Store',
      },
    },

    // Redirect to 404 page, if no match found
    {
      path: '*',
      name: 'page-error-404',
      component: () => import('@/views/pages/Error404.vue'),
    },
  ],
});

router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
  if (!to.path.includes('/authorize')) {
    store.dispatch('auth/refreshUserObject')
      .catch(e => {
      });
  }

  let name;

  if (to.meta.pageTitle) {
    name = to.meta.pageTitle;
  } else {
    if (to.name) {
      name = to.name.replaceAll('-', ' ');
      if (this.$options && this.$options.filters) {
        name = this.$options.filters.title(name);
      }
    }
  }

  document.title = `${name} | Rapid Seminars`;

  if (Vue.prototype.$gateway) {
    Vue.prototype.$gateway.setPresenceAsBrowsing();
  }
});

router.beforeEach((to, from, next) => {

  if (to.path === '/authorize') {

    if (localStorage.getItem('sessionToken') !== null) {
      // return router.push({path: '/'})
    }
    return next();
  }

  // If auth required, check login. If login fails redirect to login page
  if (!to.path.includes('/authorize')) {
    if (!localStorage.getItem('sessionToken')) {
      return router.push({path: '/authorize', query: {to: to.path}});
    }
  }

  return next();

});

export default router;
