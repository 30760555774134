// en, de, fr, pt
export default {
  en: {
    'Exit to Portal': 'Exit to Portal',
    'My Portal': 'My Portal',
    'Course Selector': 'Course Selector',
    'Learning': 'Learning',
    Attachments: 'Attachments',
    Proctor: 'Proctor',
    Lessons: 'Lessons',
    Videos: 'Videos',
    Modules: 'Modules',
    Courses: 'Courses',
    Invoices: 'Invoices',
    Services: 'Services',
    Subscriptions: 'Subscriptions',
    DevOps: 'DevOps',
    FAQs: 'FAQs',
    Accounting: 'Accounting',
    Products: 'Products',
    Editor: 'Editor',
    Management: 'Management',
    Users: 'Users',
    Blogs: 'Blogs',
    Dashboard: 'Dashboard',
    eCommerce: 'eCommerce',
    Apps: 'Apps',
    Todo: 'Todo',
    Chat: 'Chat',
    Email: 'Email',
    Calendar: 'Calendar',
    FullCalendar: 'Full Calendar',
    SimpleCalendar: 'Simple Calendar',
    Shop: 'Shop',
    ItemDetails: 'Item Details',
    Cart: 'Cart',
    WishList: 'Wish List',
    Checkout: 'Checkout',
    UI: 'UI',
    DataList: 'Data List',
    ListView: 'List View',
    ThumbView: 'Thumb View',
    Grid: 'Grid',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Colors',
    Card: 'Card',
    Basic: 'Basic',
    Statistics: 'Statistics',
    Analytics: 'Analytics',
    CardActions: 'Card Actions',
    CardColors: 'Card Colors',
    Table: 'Table',
    agGridTable: 'agGrid Table',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Button',
    ButtonGroup: 'Button Group',
    Chip: 'Chip',
    Collapse: 'Collapse',
    Dialogs: 'Dialogs',
    Divider: 'Divider',
    DropDown: 'DropDown',
    List: 'List',
    Loading: 'Loading',
    Navbar: 'Navbar',
    Notifications: 'Notifications',
    Pagination: 'Pagination',
    Popup: 'Popup',
    Progress: 'Progress',
    Sidebar: 'Sidebar',
    Slider: 'Slider',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Upload: 'Upload',
    FormsAndTable: 'Forms & Table',
    FormElements: 'Form Elements',
    Select: 'Select',
    Switch: 'Switch',
    Checkbox: 'Checkbox',
    Radio: 'Radio',
    Input: 'Input',
    NumberInput: 'Number Input',
    Textarea: 'Textarea',
    FormLayouts: 'Form Layouts',
    FormWizard: 'Form Wizard',
    FormValidation: 'Form Validation',
    FormInputGroup: 'Form Input Group',
    Pages: 'Pages',
    Authentication: 'Authentication',
    Login: 'Login',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    ResetPassword: 'Reset Password',
    LockScreen: 'Lock Screen',
    Miscellaneous: 'Miscellaneous',
    ComingSoon: 'Coming Soon',
    Error: 'Error',
    404: '404',
    500: '500',
    NotAuthorized: 'Not Authorized',
    Maintenance: 'Maintenance',
    Profile: 'Profile',
    User: 'User',
    View: 'View',
    Edit: 'Edit',
    UserSettings: 'User Settings',
    Invoice: 'Invoice',
    FAQ: 'FAQ',
    Search: 'Search',
    KnowledgeBase: 'Knowledge Base',
    ChartsAndMaps: 'Charts & Maps',
    Charts: 'Charts',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Map',
    Extensions: 'Extensions',
    QuillEditor: 'Quill Editor',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Datepicker',
    DatetimePicker: 'Datetime Picker',
    AccessControl: 'Access Control',
    I18n: 'I18n',
    Carousel: 'Carousel',
    Clipboard: 'Clipboard',
    ContextMenu: 'Context Menu',
    StarRatings: 'Star Ratings',
    Autocomplete: 'Autocomplete',
    Tree: 'Tree',
    // "Import/Export": 'Import/Export',
    Import: 'Import',
    Export: 'Export',
    ExportSelected: 'Export Selected',
    Others: 'Others',
    MenuLevels: 'Menu Levels',
    MenuLevel2p1: 'Menu Level 2.1',
    MenuLevel2p2: 'Menu Level 2.2',
    MenuLevel3p1: 'Menu Level 3.1',
    MenuLevel3p2: 'Menu Level 3.2',
    DisabledMenu: 'Disabled Menu',
    Support: 'Support',
    Documentation: 'Documentation',
    RaiseSupport: 'Raise Support',
    demoTitle: 'Card Title',
    demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.'
  }
}
